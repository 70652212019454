import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	pageName: string = 'home';
	
	
	constructor(private router: Router) {
		console.log('Footer constructor called');
	}

	ngOnInit() {
		this.pageName = this.router.url;
		//this.router pageName: string = 'home';
	}

	goTo(pageName) {
		
		this.router.navigate([this.pageName = '/' + pageName ]);
	}

}

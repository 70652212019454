import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';

const routes: Routes = [
	 {
		path: '',
		redirectTo: '/trending',
		pathMatch: 'full'
	},
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{ 
				path: 'trending', 
				loadChildren: () => import('../trending/trending.module').then(m => m.TrendingModule) },
		]
	}, 
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{ 
				path: 'search', 
				loadChildren: () => import('../search/search.module').then(m => m.SearchModule) },
		]
	},
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{ 
				path: 'search/:term', 
				loadChildren: () => import('../search/search.module').then(m => m.SearchModule) },
		]
	},
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{ 
				path: 'topics', 
				loadChildren: () => import('../topics/topics.module').then(m => m.TopicsModule) },
		]
	}, 
	{
		path: '',
		component: MainLayoutComponent,
		children: [
			{ 
				path: 'topics/:topic', 
				loadChildren: () => import('../topics/topics.module').then(m => m.TopicsModule) },
		]
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})

export class LayoutRoutingModule { 

}

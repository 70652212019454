import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  pageName: string = 'home';
  constructor(private router: Router) {
    console.log('Header constructor called');
  }

  ngOnInit() {
  }

  goTo(pageName) {
		
		this.router.navigate([this.pageName = '/' + pageName ]);
	}

}

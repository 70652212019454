
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {LayoutModule} from './layout/layout.module';
import { HttpClientModule } from '@angular/common/http'; 

@NgModule({
  declarations: [ AppComponent ],
  imports: [ BrowserModule, HttpClientModule, RouterModule.forRoot([]), /*RouterModule.forRoot([], { preloadingStrategy: PreloadAllModules }),*/ LayoutModule,],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
